<template>
  <div class="lstion">
    <div class="item mb30 cup" :style="{backgroundColor:current==index?item.hue:''}" v-for="(item, index) in list" :key="index" @click="tap(item, index)">
      <div class="hn flex">
        <div class="litle" :style="{color:current==index?item.hue:''}"><img :src="item.fbIcon" ></div>
        <div class="font f12">{{item.sujectName}}</div>
      </div>
      <!-- <div class="num"><img :src="item.icon" ></div> -->
      <div class="num" :class="{active: current==index}">
        <div class="flex col-top f18">
          <div>{{index + 1}}</div>
          <div>{{item.title}}</div>
        </div>
      </div>
      <div class="cut" v-if="current==index"></div>
    </div>
  </div>
</template>

<script>
export default {
  name:'',
  props: ['list', 'current'],
  data(){
   return {

   }
  },
  methods: {
    tap(item, index){
      var obj = {
        item: item,
        index: index
      }
      this.$emit('click', obj);
    }
  },
}
</script>

<style lang="scss" scoped>
  .lstion{
    overflow: auto;
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
    .item{
      position: relative;
      width: 138px;
      height: 176px;
      background-color: #d9e9ff;
      border-radius: 20px;
      .cut{
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 10px solid #ff5353;
        border-bottom: 7px solid transparent;
      }
      .num{
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 90px;
        padding: 0 10px;
        color: #FFFFFF;
        font-weight: bold;
        letter-spacing: 6px;
        line-height: 1.6;
          display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden; 
        img{
          width: 100%;
        }
        &.active{
          &::after{
            content: '';
            display: inline-block;
            width: 12px;
            height: 100%;
            border-radius: 12px;
            background-color: #FFFFFF;
            position: absolute;
            right: -6px;
            bottom: 0;
          }
        }
        
      }
      .hn{
        position: absolute;
        left: 14px;
        top: 11px;
        .font{
          font-weight: bold;
          color: #FFFFFF;
          padding-left: 6px;
        }
        .litle{
          font-weight: bold;
          position: relative;
          line-height: 20px;
          width: 20px;
          height: 20px;
          // padding: 3px;
          line-height: 20px;
          border-radius: 3px;
          background-color: #FFFFFF;
          font-size: 10px;
          color: #d9e9ff;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
</style>
